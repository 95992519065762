import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { AlertaService } from './alerta.service';

@NgModule({
  imports: [
    ToastrModule,
    ToastrModule.forRoot(),
  ],
  providers: [AlertaService],
  exports: []
})
export class AlertaModule { }
