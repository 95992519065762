<mat-card-header>
    <mat-card-title>
        <div class="" style="display: flex;">
            <div class="card-icon">
                <i class="material-icons">{{icon}}</i>
            </div>
            <!-- <mat-icon matPrefix style="margin-top: 4px !important;margin-right: 10px !important;">{{icon}}</mat-icon> -->
            <h2 class="mat-h2" style="margin-bottom: 0; margin-top: 0;">{{label}}</h2>
            <ng-content select="app-title-button">
            </ng-content>
        </div>
    </mat-card-title>
</mat-card-header>
