import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService) { }

  @Input() loading: boolean = false;
  @Input() texto: string = 'Cargando...';

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges){
    if(changes.loading){
      this.loading ? this.spinner.show() : this.spinner.hide();
    }
  }

}
