import { Injectable } from '@angular/core';
import { ToastrService  } from 'ngx-toastr';
import { AlertaPosition } from './alerta-position.enum';

@Injectable()
export class AlertaService {

  constructor(public toastr: ToastrService) { }

  warn(message: string, position: AlertaPosition = AlertaPosition.topRight): void {
    this.toastr.warning(message, 'Aviso', { positionClass: position, enableHtml: true });
  }

  error(message: string, detail?: string, position: AlertaPosition = AlertaPosition.topRight): void {
    this.toastr.error(message, 'Error', { positionClass: position, enableHtml: true });
  }

  info(message: string, size?: string, position: AlertaPosition = AlertaPosition.topRight): void {
    this.toastr.info(message, 'Información', { positionClass: position, enableHtml: true });
  }

  success(message: string, size?: string, position: AlertaPosition = AlertaPosition.topRight): void {
    this.toastr.success(message, 'Correcto', { positionClass: position, enableHtml: true });
  }

}
