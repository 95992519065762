import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IModuloJerarquicoHabilitadoDto } from 'src/app/auth/models';
import { AuthDataService } from 'src/app/auth/services/auth-data.service';

@Injectable({
  providedIn: 'root'
})
export class ModuloChangeService {

  private _modules: IModuloJerarquicoHabilitadoDto[] = [];
  private _modulePath: IModuloJerarquicoHabilitadoDto[] = [];
  private _moduleSelected: IModuloJerarquicoHabilitadoDto;

  constructor(private router: Router,
    // private authData: AuthDataService
  ) { }

  get modules() {
    return this._modules;
  }

  get modulePath() {
    return this._modulePath;
  }

  get moduleSelected() {
    return this._moduleSelected;
  }

  updateSelect(pItem: IModuloJerarquicoHabilitadoDto) {
    this._updateSelect(pItem);
  }

  remoteLast() {
    this._removeLast()
  }

  modulesInit() {
    this._modules = [
      {
        Id: 1,
        Titulo: 'CLIENTES',
        Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
        Icono: 'fa fa-users',
        Visible: true,
        Url: '',
        Hijos: [
          {
            Id: 9,
            Titulo: 'LISTADO DE CLIENTES',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-users',
            Visible: true,
            Url: 'clientes/list',
            Hijos: []
          },
          {
            Id: 10,
            Titulo: 'AJUSTES DE SALDO',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-university',
            Visible: true,
            Url: 'clientes/ajuste-saldo',
            Hijos: []
          }
        ]
      },
      {
        Id: 30,
        Titulo: 'PROVEEDORES',
        Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
        Icono: 'fa fa-level-up',
        Visible: true,
        Url: '',
        Hijos: [
          {
            Id: 9,
            Titulo: 'LISTADO DE PROVEEDORES',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-users',
            Visible: true,
            Url: 'proveedores/list',
            Hijos: []
          }
        ]
      },
      {
        Id: 2,
        Titulo: 'VENTAS',
        Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
        Icono: 'fa fa-money',
        Visible: true,
        Url: '',
        Hijos: [
          {
            Id: 3,
            Titulo: 'COMPROBANTES AFIP',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-ticket',
            Visible: true,
            Url: 'ventas/comprobantes-afip/list',
            Hijos: []
          },
          {
            Id: 4,
            Titulo: 'COMPROBANTES EMITIDOS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-ticket',
            Visible: true,
            Url: 'ventas/comprobantes-emitidos/list',
            Hijos: []
          },
          {
            Id: 8,
            Titulo: 'LIBRO DE FACTURACIÓN',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-book',
            Visible: true,
            Url: 'ventas/libro-facturacion/list',
            Hijos: []
          },
          {
            Id: 11,
            Titulo: 'EMITIR COMPROBANTE',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-ticket',
            Visible: true,
            Url: 'ventas/comprobantes-emitidos/new/0',
            Hijos: []
          }
        ]
      },
      {
        Id: 23,
        Titulo: 'CAJAS',
        Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
        Icono: 'fa fa-shopping-cart',
        Visible: true,
        Url: '',
        Hijos: [
          {
            Id: 24,
            Titulo: 'MOVIMIENTOS CAJA',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-shopping-cart',
            Visible: true,
            Url: 'cajas/movimientos-caja-list',
            Hijos: []
          },
          {
            Id: 25,
            Titulo: 'COBROS CON EFECTIVO',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-money',
            Visible: true,
            Url: 'cajas/efectivos-list',
            Hijos: []
          },
          {
            Id: 26,
            Titulo: 'COBROS CON CHEQUES',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-list-alt',
            Visible: true,
            Url: 'cajas/cheques-list',
            Hijos: []
          },
          {
            Id: 27,
            Titulo: 'COBROS CON TARJETA',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-credit-card-alt',
            Visible: true,
            Url: 'cajas/cajas-list',
            Hijos: []
          },
          {
            Id: 28,
            Titulo: 'COBROS CON DEPÓSITOS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-university',
            Visible: true,
            Url: 'cajas/depositos-list',
            Hijos: []
          }
        ]
      },
      {
        Id: 5,
        Titulo: 'STOCK',
        Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
        Icono: 'fa fa-archive',
        Visible: true,
        Url: '',
        Hijos: [
          {
            Id: 6,
            Titulo: 'PRODUCTOS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-product-hunt',
            Visible: true,
            Url: 'stock/productos/list',
            Hijos: []
          },
          {
            Id: 7,
            Titulo: 'AJUSTES DE STOCK',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-bar-chart',
            Visible: true,
            Url: 'stock/ajustes',
            Hijos: []
          }
        ]
      },
      {
        Id: 12,
        Titulo: 'CONFIGURACIÓN',
        Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
        Icono: 'fa fa-cog',
        Visible: true,
        Url: '',
        Hijos: [
          {
            Id: 13,
            Titulo: 'SUCURSALES FÍSICAS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-home',
            Visible: true,
            Url: 'configuraciones/sucursales-fisicas',
            Hijos: []
          },
          {
            Id: 14,
            Titulo: 'PUNTOS DE VENTA',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-laptop',
            Visible: true,
            Url: 'configuraciones/puntos-de-ventas',
            Hijos: []
          },
          {
            Id: 15,
            Titulo: 'CAJAS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-shopping-cart',
            Visible: true,
            Url: 'configuraciones/cajas',
            Hijos: []
          },
          {
            Id: 16,
            Titulo: 'USUARIOS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-users',
            Visible: true,
            Url: 'configuraciones/usuarios',
            Hijos: []
          },
          {
            Id: 17,
            Titulo: 'RUBROS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-list-ul',
            Visible: true,
            Url: 'configuraciones/rubros',
            Hijos: []
          },
          {
            Id: 18,
            Titulo: 'TESORERIA',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-fax',
            Visible: true,
            Url: '',
            Hijos: [{
              Id: 19,
              Titulo: 'BANCOS',
              Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
              Icono: 'fa fa-university',
              Visible: true,
              Url: 'configuraciones/tesoreria/bancos',
              Hijos: []
            },
            {
              Id: 20,
              Titulo: 'BANCOS PARA DEPÓSITOS',
              Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
              Icono: 'fa fa-university',
              Visible: true,
              Url: 'configuraciones/tesoreria/bancos-depositos',
              Hijos: []
            },
            {
              Id: 21,
              Titulo: 'TARJETAS DE CRÉDITO',
              Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
              Icono: 'fa fa-credit-card-alt',
              Visible: true,
              Url: 'configuraciones/tesoreria/tarjetas-credito',
              Hijos: []
            },
            ]
          },
          {
            Id: 22,
            Titulo: 'LISTAS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-file-excel-o',
            Visible: true,
            Url: 'configuraciones/listas',
            Hijos: []
          },
          {
            Id: 29,
            Titulo: 'MONTOS MÁXIMOS',
            Background: 'linear-gradient(60deg,#C78D4D,#f5cb74)',
            Icono: 'fa fa-level-up',
            Visible: true,
            Url: 'configuraciones/montos-maximos',
            Hijos: []
          }
        ]
      }
    ]
    // this._modules = this.authData.getCredentialUser().Modulos;
    this.resetPath();
  }

  private _updateSelect(pItem: IModuloJerarquicoHabilitadoDto) {
    if (!pItem.Hijos || !pItem.Hijos.length) {
      this.router.navigate([`/app/${pItem.Url}`]);
    } else {
      this.router.navigate(['/app']);
      this._moduleSelected = pItem;
      this.addOne(pItem);
    }
  }

  private _removeLast() {
    this._modulePath.pop();
    this._moduleSelected = this.getLast();
  }

  private addOne(pItem) {
    this._modulePath.push(pItem);
  }

  private getLast() {
    return this._modulePath[this._modulePath.length - 1];
  }

  resetPath() {
    this._modulePath = [];
  }
}
