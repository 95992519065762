import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { TitleComponent } from './title.component';
import { TitleButtonComponent } from './title-button.component';



@NgModule({
  declarations: [
    TitleComponent,
    TitleButtonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    TitleComponent,
    TitleButtonComponent
  ]
})
export class TitleModule { }
