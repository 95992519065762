import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {

  private prefix = 'appGC';

  constructor() { }

  getKey(key: string) {
    return this.prefix + '.' + key;
  }

  public get(key: string): any {
    if (!key) return;

    let value = localStorage.getItem(this.getKey(key));
    if (!value) return;

    let parseValue = JSON.parse(value)
    return parseValue;
  }

  public set(key: string, value: any) {
    if (!key || !value) return;
    let valueString = JSON.stringify(value);
    localStorage.setItem(this.getKey(key), valueString);
  }

  public removeItem(key: string): any {
    if (!key) return;
    return localStorage.removeItem(this.getKey(key));
  }

  public removeItems(key: string): any {
    if (!key || !key.length) return;
    return localStorage.removeItem(this.getKey(key));
  }

  public clearAll(): any {
    return localStorage.clear();
  }
}
