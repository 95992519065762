import { Component, ContentChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent implements OnInit {

  @ContentChild("modalHeader") modalHeaderTemplate;
  @ContentChild("modalBody") modalBodyTemplate;
  @ContentChild("modalFooter") modalFooterTemplate;

  constructor() { }

  ngOnInit(): void {
  }

}
