import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IModuloJerarquicoHabilitadoDto } from 'src/app/auth/models';
import { AuthDataService } from 'src/app/auth/services/auth-data.service';
import { ModuloChangeService } from '../../services/modulo-change.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  subscribes: Subscription[] = [];

  constructor(
    private authData: AuthDataService,
    public moduloChangeSvc: ModuloChangeService) {}

  ngOnInit(): void {
      this.subscribes.push(this.authData.tokenActivo().subscribe());
  }

  ngOnDestroy() {
    this.subscribes.forEach(s => s.unsubscribe());
  }

  updateSelect(pItem: IModuloJerarquicoHabilitadoDto) {
    this.moduloChangeSvc.updateSelect(pItem);
  }

  removeLast() {
    this.moduloChangeSvc.remoteLast();
  }

}
