import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-home',
  templateUrl: './card-home.component.html',
  styleUrls: ['./card-home.component.scss']
})
export class CardHomeComponent implements OnInit {

  @Input() name: string;
  @Input() icon: string;
  @Input() url: string;
  @Input() hijos: number = 0;
  @Input() background: string = '';
  
  constructor() { }

  ngOnInit() {
  }

}
