declare const require: any;

export const environment = {
    name: 'developing',
    class: 'developing',
    production: true,
    appVersion: require('../../package.json').version,
  
    apiUrl: 'https://dev.elimanagro.com.ar/api/',
  };
  