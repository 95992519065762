<mat-card>
    <div mat-dialog-title style="display: flex; margin-bottom: 10px !important">
        <mat-icon matPrefix style="margin-top: 4px !important;margin-right: 10px !important;">home</mat-icon>
        <h2 class="mat-h2 margin-form-h">HOME</h2>
    </div>

    <mat-card-content>
        <div class="card-container">
            <div class="home-card" *ngFor="let module of moduloChangeSvc.modules">
                <app-card-home *ngIf="!moduloChangeSvc.modulePath.length && module.Visible" [name]="module.Titulo" [icon]="module.Icono"
                [background]="module.Background" [hijos]="module.Hijos.length" (click)="updateSelect(module)">
                </app-card-home>
            </div>
        </div>

        <div *ngIf="moduloChangeSvc.modulePath.length">
            <div style="display: flex; padding-bottom: 7px">
                <span *ngFor="let path of moduloChangeSvc.modulePath; let first = first; let last = last">
                    <span *ngIf="!first" class="ml-2 mr-2">
                        <span class="fa fa-chevron-right"></span>
                    </span>
                    <span>{{ path.Titulo }}</span>
                    <span *ngIf="last" class="ml-2" (click)="removeLast()" style="cursor: pointer">
                        <span class="fa fa-times"></span>
                    </span>
                </span>
            </div>
        </div>

        <div class="card-container" *ngIf="moduloChangeSvc.modulePath.length">
            <div class="home-card">
                <app-card-home name="ATRAS" icon="fa fa-arrow-left" (click)="removeLast()">
                </app-card-home>
            </div>
            <div class="home-card" *ngFor="let module of moduloChangeSvc.moduleSelected.Hijos" [hidden]="!module.Visible">
                <app-card-home [name]="module.Titulo" [icon]="module.Icono" [background]="module.Background"
                    [hijos]="module.Hijos.length" (click)="updateSelect(module)">
                </app-card-home>
            </div>
        </div>

    </mat-card-content>

</mat-card>