import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertaModule } from './alerta/alerta.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StorageModule } from './storage/storage.module';
// import { AuthGuard } from './security/guards/auth.guard';
// import { PermissionGuardService } from './security/guards/permission.guard';
import { AppContainerComponent } from './container/app-container/app-container.component';
import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../shared/material/material.module';
import { SharedModule } from '../shared/shared.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import './prototypes';

@NgModule({
  declarations: [
    AppContainerComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AlertaModule,
    StorageModule,
    AppRoutingModule,
    MaterialModule,
    SharedModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
    // AuthGuard,
    // PermissionGuardService
  ]
})
export class CoreModule { }
