<app-modal-container>
    <ng-template #modalHeader>
        <app-title label="{{options.Title}}" icon="info">
        </app-title>
      <mat-divider></mat-divider>
    </ng-template>
    
    <ng-template #modalBody>

        <div class="row mt-3">
          
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h3 [innerHTML]="options.Message"></h3>

             </div>
          </div>

      <br>
    
      <mat-divider></mat-divider>
    </ng-template>
    
    <ng-template #modalFooter>
      <div class="col-sm-12 col-md-12 col-lg-12 text-right">
          <button type="button" mat-stroked-button color="secondary" class="mr-2" (click)="dismiss()">Cancelar</button>
          <button mat-stroked-button class="btn-mat-primary" (click)="okValue()">Confirmar</button>
      </div>
    </ng-template>
    
</app-modal-container>

  
