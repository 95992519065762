import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, concatMap, Observable, tap, throwError } from 'rxjs';
import { AlertaService } from '../alerta/alerta.service';
import { StorageService } from '../storage/storage.service';
import { AuthDataService } from 'src/app/auth/services/auth-data.service';
import { IValidationResultDto } from '../typings';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authData: AuthDataService,
    private alertaService: AlertaService,
    private route: Router
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(this.addToken(request)).pipe(
      catchError((e: HttpErrorResponse) => {
        switch (e.status) {
          case 400:
            this.alertaService.error(e.error.message, 'Error')
            return throwError(() => e);
          case 404:
            this.alertaService.error('Página no encontrada!', 'Error')
            return throwError(() => e);
          case 401:
            if (e.error.name == 'TokenExpiredError' && e.url && !e.url.includes('refreshToken')) {
              //Refresh token
              return this.authData.refreshToken().pipe(concatMap(
                _data => {
                  this.authData.setCredentialUser(_data);
                  return next.handle(this.addToken(request));
                }))
            } else {
              // Logout
              this.alertaService.info('La sesión ah expirado, ingrese nuevamente');
              this.route.navigate(['/auth/login']);
              return throwError(() => e);
            }
          default:
            this.alertaService.error('Error de servidor', 'Error');
            // this.authData.logout();
            return throwError(() => e);
        }
      })
    );
  }

  addToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.authData.getCredentialUser()?.Token
      }
    });
    return request
  }
}
