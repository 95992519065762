import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IModalConfirmType } from './modal-confirm-dto';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  options: IModalConfirmType;

  constructor(public activeModal: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.options = this.data.options;
  }

  okValue() {
    this.activeModal.close(true);
  }

  dismiss() {
    this.activeModal.close(false);
  }

}