import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConfirmComponent } from './modal-confirm.component';
import { ModalConfirmService } from './modal-confirm.service';
import { MaterialModule } from '../../material/material.module';
import { ModalModule } from '../modal/modal.module';
import { TitleModule } from '../title/title.module';



@NgModule({
  declarations: [
    ModalConfirmComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ModalModule,
    TitleModule,
    
  ],
  exports: [
    ModalConfirmComponent
  ],
  providers: [
    ModalConfirmService,
  ]
})
export class ModalConfirmModule { }
