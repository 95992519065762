export { }

Date.prototype.parseToBe = function () {
  let vm = this;
  let day = vm.getDate();
  let month = vm.getMonth() + 1;
  let year = vm.getFullYear();
  let hour = vm.getHours();
  hour = ("0" + hour).slice(-2);
  let minutes = vm.getMinutes();
  minutes = ("0" + minutes).slice(-2);
  let seconds = vm.getSeconds();
  let dateAsString = `${month}/${day}/${year} ${hour}:${minutes}`;
  return dateAsString;
}

Date.prototype.parseToBeNoHour = function () {
  let vm = this;
  let day = vm.getDate();
  let month = vm.getMonth() + 1;
  let year = vm.getFullYear();
  let dateAsString = `${month}/${day}/${year} 00:00`;
  return dateAsString;
}

Date.prototype.parseToBeParams = function() {
  let vm = this;
  let day = vm.getDate();
  let month = vm.getMonth() + 1;
  let year = vm.getFullYear();
  // let hour = vm.getHours();
  // let minutes = vm.getMinutes();
  // let seconds = vm.getSeconds();
  let dateAsString = `${month}-${day}-${year}`;
  return dateAsString;
}

Date.getYearsOld = function(pFechaNacimiento: Date, pFechaComparacion?: Date) {
  const fechaHoy = pFechaComparacion || new Date();
  // var array_fecha = pFechaNacimiento.split("-") ;
  // var anoNacimiento  = parseInt(array_fecha[2]);
  const anoNacimiento  = pFechaNacimiento.getFullYear();
  const anohoy = fechaHoy.getFullYear();
  var edad = anohoy - anoNacimiento;
  // obtenemos el mes y dia 
  const meshoy = fechaHoy.getMonth();
  const mesNacimiento = pFechaNacimiento.getMonth();
  const diahoy = fechaHoy.getDate();
  const diaNacimiento = pFechaNacimiento.getDate();

  if (meshoy < mesNacimiento) {
      edad--;
  }
  if ((meshoy === mesNacimiento) && (diahoy < diaNacimiento)) {
    edad--;
  }
  return edad;
}

Date.parseToFe = function (stringDate:string) {
  if (!stringDate) return null;
  if (stringDate === '0001-01-01T00:00:00') return null;
  // input like 	'2016-05-20T10:54:00'
  // 				"2017-02-17T00:00:00"
  var year, month, days, hours, minutes, seconds, miliseconds;
  year = stringDate.substr(0, 4);
  month = stringDate.substr(5, 2);
  days = stringDate.substr(8, 2);
  hours = stringDate.substr(11, 2);
  minutes = stringDate.substr(14, 2);
  seconds = stringDate.substr(17, 2);

  // year =  this.$filter('limitTo')(stringDate, 4);
  // month = this.$filter('limitTo')(stringDate, 2, 5);
  // days = this.$filter('limitTo')(stringDate, 2, 8);
  // hours = this.$filter('limitTo')(stringDate, 2, 11);
  // minutes = this.$filter('limitTo')(stringDate, 2, 14);
  // seconds = this.$filter('limitTo')(stringDate, 2, 17);
  miliseconds = 0;

  return new Date(year, month - 1, days, hours, minutes, seconds, miliseconds);
}

Date.prototype.addHours = function (hours: number) {
  var date = this;
  date.setHours(date.getHours() + hours);
  return date;
}

Date.prototype.addHoursMinutes = function (hoursMinutes: string) {
  var date = this;
  var hours = hoursMinutes.substr(0,2);
  var minutes = hoursMinutes.substr(3, 4);
  date.setHours(date.getHours() + hours);
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

Date.prototype.setHoursMinutes = function (hoursMinutes: string) {
  var date = this;
  var hours = hoursMinutes.substr(0, 2);
  var minutes = hoursMinutes.substr(3, 4);
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

Date.prototype.addMinutes = function (minutes: number) {
  var date = this;
  date.setMinutes(date.getMinutes() + minutes);
  return date;
}

Date.prototype.addDays = function(days: number) {
  var date = this;
  date.setDate(date.getDate() + days);
  return date;
}

// Date.prototype.addMonths = function (months: number) {
//   var date = this;
//   date.setMonth(date.getMonth() + months);
//   return date;
// }

Date.addMonths = function (date: Date, months: number) {
  let monthsCount = date.getMonth() + months;
  let dateReturn = new Date(date);
  dateReturn.setMonth(monthsCount);
  return dateReturn;
}