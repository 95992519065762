<div class="card card-stats">
	<div class="card-header card-header-warning card-header-icon">
		<div class="card-icon" [ngClass]="{'card-icon-back': name == 'ATRAS'}" [ngStyle]="{background}">
			<i class="material-icons" [ngClass]="icon"></i>
		</div>
		<p class="card-category">{{name}}</p>
		<h4 *ngIf="hijos > 0" class="card-title">Modulos internos: {{hijos}}</h4>
		<h4 *ngIf="hijos == 0" class="card-title">&nbsp;</h4>
	</div>
	<div class="card-footer">
		<div *ngIf="name != 'ATRAS'" class="stats">Click para ingresar</div>
		<div class="stats">&nbsp;</div>
	</div>
</div>