import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IInfoUsuarioDto } from 'src/app/container/usuario/models/info-usuario-dto';
import { UsuarioDataService } from 'src/app/container/usuario/services/usuario-data.service';
import { StorageService } from 'src/app/core/storage/storage.service';
import { IValidationResultDto } from 'src/app/core/typings';
import { environment } from 'src/environments/environment';
import { IChangePasswordDto } from '../models/change-password-dto';
import { ICredentialUserDto } from '../models/credential-users-dto';
import { IUserAuthInfoDto } from '../models/user-auth-dto';
@Injectable({
  providedIn: 'root'
})
export class AuthDataService {

  private baseUrl: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private usuarioData: UsuarioDataService,
    private router: Router) { }

  login(userParams: IUserAuthInfoDto) {
    let url = this.baseUrl + "auth/loginusuario"
    return this.http.post<ICredentialUserDto>(url, userParams)
      .pipe(
        map(async data => {
          this.setCredentialUser(data);
          await this.setInfoUsuario();
          this.router.navigate(['/app']);
        }));
  }

  setInfoUsuario(){
    return new Promise((resolve, reject) => {
      this.usuarioData.getInfoUsuario().subscribe(_infoUsuario => {
        this.storage.set('infoUsuario', _infoUsuario);
        resolve(_infoUsuario);
      })
    })
  }

  getInfoUsuario(): IInfoUsuarioDto{
    return this.storage.get('infoUsuario');
  }

  setCredentialUser(data: ICredentialUserDto) {
    this.storage.set('credentials', data);
  }

  getCredentialUser(): ICredentialUserDto {
    return this.storage.get('credentials');
  }

  getPrueba() {
    let url = this.baseUrl + "auth/pruebaTokenYRefresh"
    return this.http.get<any>(url);
  }

  tokenActivo(){
    let RefreshToken = this.getCredentialUser()?.RefreshToken;
    let url = `${this.baseUrl}auth/tokenActivo`;
  	 return this.http.post<IValidationResultDto>(url, { RefreshToken });
  }

  refreshToken() {
    let RefreshToken = this.getCredentialUser()?.RefreshToken;
    let url = this.baseUrl + "auth/refreshToken"
    return this.http.post<ICredentialUserDto>(url, { RefreshToken });
  }

  changePasswordCurrentUser(changePassDto: IChangePasswordDto) {
    // let url = this.baseUrl + "UsuarioAcceso/ChangePassword"
    // return this.http.post<IValidationResultDto>(url, changePassDto);
  }

  logout() {
    this.storage.clearAll();
  }
}
