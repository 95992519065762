import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IModalConfirmType } from './modal-confirm-dto';
import { ModalConfirmComponent } from './modal-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class ModalConfirmService {
  constructor(private modalService: MatDialog) { }

  confirm(options: IModalConfirmType) {
    const modalInstance = this.modalService.open(ModalConfirmComponent,{
      width: options.Size || '500px',
      data: {
        options
      }
    });

    return modalInstance.afterClosed();
  }


}