import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { TitleButtonComponent } from './title-button.component';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {

  @Input() label: string;
	@Input() icon: string;

	@ContentChild('app-title-button', {static: true}) singleButton: TitleButtonComponent;


  constructor() { }

	ngOnInit() {

	}

}
