<mat-sidenav-container class="sidenav-container" fullscreen>

    <mat-sidenav #drawer [mode]="!(isHandset$ | async) ? 'side':'over'" [opened]="!(isHandset$ | async)"
        class="side-nav mat-elevation-z4">
        <div [@onSideNavChange]="sideNavState ? 'open' : 'close'">

            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                [hideToggle]="true">
                <mat-expansion-panel-header>
                    <img class="jim" src="https://i.pravatar.cc/100">
                    <!-- <span [@animateText]="linkText ? 'show' : 'hide'">{{user?.Nombre}}</span> -->
                    <span [@animateText]="linkText ? 'show' : 'hide'" style="color: #111312">{{payLoadUser?.nombreApellido}}</span>
                    <!-- <button mat-icon-button> -->
                        <mat-icon [@animateText]="linkText ? 'show' : 'hide'" *ngIf="panelOpenState" style="color: #111312">arrow_drop_up</mat-icon>
                        <mat-icon [@animateText]="linkText ? 'show' : 'hide'" *ngIf="!panelOpenState" style="color: #111312">arrow_drop_down</mat-icon>
                    <!-- </button> -->
                </mat-expansion-panel-header>
                <mat-nav-list>
                    <a mat-list-item (click)="pruebaToken()">
                        <mat-icon class="icon-mat-list-item" fontSet="fa" fontIcon="fa-address-card-o" matTooltip="Mi Perfil"
                        matTooltipPosition="after"></mat-icon>
                        <span class="label-mat-list-item" [@animateText]="linkText ? 'show' : 'hide'">Mi perfil
                        </span>
                    </a>
                </mat-nav-list>
            </mat-expansion-panel>

            <mat-divider style="background-color: #111312; margin: 0 13px"></mat-divider>

            <mat-nav-list>
                <a mat-list-item *ngFor="let page of items" (click)="onMenuItemClick(page)">
                    <mat-icon class="icon-mat-list-item" fontSet="fa" matTooltip="{{page?.name | titlecase}}"
                        matTooltipPosition="after" fontIcon="{{page?.icon}}"></mat-icon>
                    <span class="label-mat-list-item" [@animateText]="linkText ? 'show' : 'hide'">{{ page?.name }}
                    </span>
                </a>
            </mat-nav-list>

            <div class="collapse-sidenav" *ngIf="!(isHandset$ | async)">
                <button mat-icon-button (click)="onSinenavToggle()">
                    <mat-icon *ngIf="sideNavState">arrow_left</mat-icon>
                    <mat-icon *ngIf="!sideNavState">arrow_right</mat-icon>
                </button>
            </div>
        </div>

    </mat-sidenav>

    <mat-sidenav-content [ngClass]="{'side-responsive': (isHandset$ | async)}"
        [@onMainContentChange]="sideNavState ? 'open': 'close'">

        <mat-toolbar class="mat-elevation-z4" style="height: 44px !important;" [ngClass]="envClass">
            <button type="button" mat-icon-button (click)="drawer.toggle()" style="color: #111312">
                <mat-icon>menu</mat-icon>
            </button>
            <!-- <span [routerLink]="['/']" id="title-menu" style="cursor: pointer">Home</span> -->
            <div>
                <!-- <h2 class="mat-h2 margin-form-h">GESTIÓN COMERCIAL</h2> -->
                <img class="sa-logo img-fluid" src="assets/img/logo-el-iman.jpeg"
                    style="cursor: pointer;" (click)="onMenuItemClick(null)">
            </div>
            <span class="sa-toolbar-spacer"></span>

            <!-- <button mat-icon-button matTooltip="Alerts" [matTooltipPosition]="'above'">
                <mat-icon *ngIf="alertasPorUsuario.length == 0" fontSet="fa" class="alerta-button" fontIcon="fa-bell-o" [matMenuTriggerFor]="notificaciones"
                    id="alerta-icon"></mat-icon>
                <mat-icon *ngIf="alertasPorUsuario.length > 0" fontSet="fa" class="alerta-button" fontIcon="fa-bell-o" [matMenuTriggerFor]="notificaciones"
                    id="alerta-icon" [matBadge]="alertasPorUsuario.length" matBadgeColor="warn"></mat-icon>
            </button> -->

            <div *ngIf="infoUsuario?.SucursalesFisicasUsuario" class="mr-2" style="font-size: 16px; cursor: pointer" [ngClass]="{'color-rojo-autorizacion-texto': diasVencCert <= 45}">
                <span>Vencimiento Cert. Fact. Electrónica: <span style="font-weight: bold">{{getCertificado() | date: "dd/MM/yyyy" : "UTC"}}</span></span>
            </div>

            <div class="toolbar-separator"></div>
            <!-- <div class="toolbar-separator"></div>

            <button mat-icon-button matTooltip="World hours" [matTooltipPosition]="'above'" [matMenuTriggerFor]="horasMundiales">
                <mat-icon fontSet="fa" class="alerta-button" fontIcon="fa-clock-o" id="wolrd-hours-icon"></mat-icon>
            </button>  -->
            <div *ngIf="infoUsuario?.SucursalesFisicasUsuario.length > 1" class="mr-2" style="font-size: 16px; cursor: pointer" [matMenuTriggerFor]="menu" matTooltip="Cambiar sucursal">
                <span>Sucursal: <span style="font-weight: bold">{{getSucursal()}}</span></span>
            </div>
            <div *ngIf="infoUsuario?.SucursalesFisicasUsuario.length == 1" class="mr-2" style="font-size: 16px; cursor: pointer">
                <span>Sucursal: <span style="font-weight: bold">{{getSucursal()}}</span></span>
            </div>

            <div class="toolbar-separator"></div>

            <button mat-icon-button matTooltip="Logout" [matTooltipPosition]="'above'" (click)="onLogout()">
                <mat-icon fontSet="fa" class="alerta-button" fontIcon="fa-power-off" id="logout-btn"></mat-icon>
            </button>

            <!-- <mat-menu #notificaciones="matMenu" xPosition="before" class="menu-notification">
                <mat-list>
                    <h3 mat-subheader (click)="$event.stopPropagation();">Notifications</h3>
                    <mat-divider></mat-divider>
                    <mat-list-item *ngFor="let alerta of alertasPorUsuario" [routerLink]="['/app/notificaciones']"
                        class="item-notification list-item-content"
                        [ngClass]="{
                            'btn-mat-danger-raised': alerta.IdCriticidad == 1,
                            'btn-mat-warning-raised': alerta.IdCriticidad == 2,
                            'btn-mat-secondary-raised': alerta.IdCriticidad == 3,
                            'btn-mat-success-raised': alerta.IdCriticidad == 4
                        }">
                        <mat-icon *ngIf="alerta.IdCriticidad == 1" mat-list-icon>warning_amber</mat-icon>
                        <mat-icon *ngIf="alerta.IdCriticidad == 2" mat-list-icon>error_outline</mat-icon>
                        <mat-icon *ngIf="alerta.IdCriticidad == 3" mat-list-icon>info_outline</mat-icon>
                        <mat-icon *ngIf="alerta.IdCriticidad == 4" mat-list-icon>check_circle_outline</mat-icon>
                        <h4 mat-line style="font-size: 13px">{{alerta.TipoNotificacion}}</h4>
                        <p mat-line style="font-size: 13px">{{alerta.TipoAlerta}}</p>
                        <p mat-line style="font-size: 12px">From {{alerta.FechaDesde | date: 'dd/MM/yyyy'}} to {{alerta.FechaHasta | date: 'dd/MM/yyyy'}} - {{alerta.Leido ? 'Leida' : 'No leida'}}</p>

                        <div class="item-options-notification">
                            <mat-icon matTooltip="Marcar como leida" mat-icon-button
                                style="font-size: 18px !important;" (click)="$event.stopPropagation();marcarComoLeido(alerta.Id)">adjust</mat-icon>
                        </div>
                    </mat-list-item>
                    <mat-list-item *ngIf="alertasPorUsuario.length == 0" (click)="$event.stopPropagation();">
                        • No active notifications to display
                    </mat-list-item>
                    <mat-list-item>
                        <button type="button" mat-stroked-button class="btn-mat-primary" [routerLink]="['/app/notificaciones']"
                        style="position: absolute; margin-top: 5px; right: 10px">View all</button>
                    </mat-list-item>
                </mat-list>
            </mat-menu> -->

        </mat-toolbar>

        <div [hidden]="loading" class="wrapper">
            <router-outlet></router-outlet>
        </div>

    </mat-sidenav-content>

</mat-sidenav-container>

<app-loading [loading]="loading"></app-loading>

<mat-menu #menu="matMenu" xPosition="before">
    <button *ngFor="let s of getOtrasSucursales()" mat-menu-item (click)="cambiarSucursal(s.Id)">{{s.Nombre}}</button>
  </mat-menu>