import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppContainerComponent } from './core/container/app-container/app-container.component';
import { HomeComponent } from './container/home/pages/home/home.component';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'app', pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'app', 
    component: AppContainerComponent, 
    canActivate: [AuthGuard],
    // canActivateChild: [PermissionGuardService],
    children: [
      {
        path: '', redirectTo: 'home', pathMatch: 'full'
      },
      {
        path:'home', component: HomeComponent
       },
      {
        path: 'clientes',
        loadChildren: () => import('./container/clientes/clientes.module').then(m => m.ClientesModule)
      },
      {
        path: 'proveedores',
        loadChildren: () => import('./container/proveedores/proveedores.module').then(m => m.ProveedoresModule)
      },
      {
        path: 'ventas',
        loadChildren: () => import('./container/ventas/ventas.module').then(m => m.VentasModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./container/stock/stock.module').then(m => m.StockModule)
      },
      {
        path: 'configuraciones',
        loadChildren: () => import('./container/configuraciones/configuraciones.module').then(m => m.ConfiguracionesModule)
      },
      {
        path: 'cajas',
        loadChildren: () => import('./container/cajas/cajas.module').then(m => m.CajasModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
