import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IValidationResultDto } from 'src/app/core/typings';
import { environment } from 'src/environments/environment';
import { IInfoUsuarioDto } from '../models/info-usuario-dto';

@Injectable({
  providedIn: 'root'
})
export class UsuarioDataService {

  private baseUrl: string = `${environment.apiUrl}usuario`;

  constructor(
    private http: HttpClient) { }

  getInfoUsuario() {
    let url = `${this.baseUrl}/getInfoUsuario`;
    return this.http.get<IInfoUsuarioDto>(url);
  }

  guardarSucursalFisicaPorDefecto(idUsuario: number, idSucursalFisica: number) {
    let url = `${this.baseUrl}/guardarSucursalFisicaPorDefecto/${idUsuario}/${idSucursalFisica}`;
    return this.http.get<IValidationResultDto>(url);
  }

}
