import { NgModule } from '@angular/core';
import { StorageService } from './storage.service';



@NgModule({
  declarations: [],
  imports: [],
  providers: [
    StorageService
  ]
})
export class StorageModule { }
