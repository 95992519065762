import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthDataService } from '../services/auth-data.service';

@Injectable()

export class AuthGuard implements CanActivate{

  constructor(private authData: AuthDataService, 
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.authData.getCredentialUser()) {
      return true;
    } else {
      this.router.navigate(['/auth/login']);
      return false
    }
  }
}
