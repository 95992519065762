import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SidebarItem } from '../models/item.model';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { animateText, onSideNavChange, onMainContentChange } from '../animations/animations';
import { SidenavService } from '../services/sidenav.service';
import { ModuloChangeService } from 'src/app/container/home/services/modulo-change.service';
import { IModuloJerarquicoHabilitadoDto } from 'src/app/auth/models';
import { AuthDataService } from 'src/app/auth/services/auth-data.service';
import jwt_decode from "jwt-decode";
import { IPayLoadUserDto } from 'src/app/container/usuario/models/payload-user-dto';
import { IInfoUsuarioDto } from 'src/app/container/usuario/models/info-usuario-dto';
import { UsuarioDataService } from 'src/app/container/usuario/services/usuario-data.service';
import { AlertaService } from '../../alerta/alerta.service';
import { AlertaPosition } from '../../alerta/alerta-position.enum';
import { ChangeUsuarioService } from 'src/app/container/usuario/services/change-usuario.service';
@Component({
  selector: 'app-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss'],
  animations: [onSideNavChange, animateText, onMainContentChange]
})
export class AppContainerComponent implements OnInit {

  env = environment;
  subscribes: Subscription[] = [];
  items: SidebarItem[] = [];
  loading = false;
  isDark = false;
  sideNavState: boolean = false;
  linkText: boolean = false;
  envClass: string;
  isHandset$: Observable<boolean>;
  panelOpenState: boolean = false;
  modules: IModuloJerarquicoHabilitadoDto[] = []
  payLoadUser: IPayLoadUserDto;
  infoUsuario: IInfoUsuarioDto;
  diasVencCert: number = 0;

  constructor(
    private authData: AuthDataService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private _sidenavService: SidenavService,
    private moduloChangeSvc: ModuloChangeService,
    private usuarioData: UsuarioDataService,
    private alertaService: AlertaService,
    private changeUsuario: ChangeUsuarioService
  ) { }

  ngOnInit() {
    this.isHandsetInit();
    this.moduloChangeSvc.modulesInit();
    this.sidebarInit();
    this.estilosYalertasToolbar();
    this.payLoadUser = jwt_decode<IPayLoadUserDto>(this.authData.getCredentialUser().Token);
    this.infoUsuario = this.authData.getInfoUsuario();

    this.calculoDiasFechaVencCert();
  }

  ngOnDestroy() {
    this.subscribes.forEach(s => s.unsubscribe());
  }

  isHandsetInit() {
    this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset)
      .pipe(map(result => {
        if (result.matches) {
          setTimeout(() => {
            this.sideNavState = true;
            this.linkText = true;
          }, 500);
        }
        return result.matches
      }));
  }

  onSinenavToggle() {
    this.sideNavState = !this.sideNavState

    if (!this.sideNavState) {
      this.linkText = this.sideNavState;
    } else {
      setTimeout(() => {
        this.linkText = this.sideNavState;
      }, 300)
    }

    this._sidenavService.sideNavState$.next(this.sideNavState)
  }

  sidebarInit() {
    this.modules = this.moduloChangeSvc.modules;

    this.items.push({
      label: '',
      icon: 'fa fa-home'.substring(2),
      name: 'HOME',
    });

    this.modules.forEach(m => {
      this.items.push({
        label: m.Titulo,
        name: m.Titulo,
        icon: m.Icono.substring(2)
      })
    });

    this.items.push({
      label: '',
      icon: 'fa fa-info-circle'.substring(2),
      name: 'About'
    });
  }

  onLogout() {
    this.router.navigate(['/auth/login']);
  }

  onMenuItemClick(item: SidebarItem) {
    this.moduloChangeSvc.resetPath();
    if (!item) {
      this.router.navigate(['/app']);
      return;
    }
    if (item.label) {
      this.moduloChangeSvc.updateSelect(this.modules.find(m => m.Titulo == item.label));
    } else {
      if (item.name == 'HOME') {
        this.router.navigate(['/app']);
      } else {
        // Ir a la pagina de about
      }
    }
    // if (item.name === 'logout') {
    //   this.onLogout();
    // } else {
    //   this.moduloChangeSvc.resetPath();
    //   this.moduloChangeSvc.updateSelect(this.modules.find(m => m.Titulo == item.label));
    // }
  }

  onShowUser() {
    //this.router.navigate(['/app/user/misDatos']);
  }

  pruebaToken() {
    this.loading = true;
    setTimeout(() => {
      this.subscribes.push(this.authData.getPrueba().subscribe(
        _resp => {
          this.loading = false;
        }));
    }, 2000);
  }

  onToggleTheme() {
    this.isDark = !this.isDark;
  }

  getSucursal(){
    if(this.infoUsuario){
      return this.infoUsuario.SucursalesFisicasUsuario.find(s => s.PorDefecto)?.Nombre;
    }
    return ''
  }

  getCertificado(){
    if(this.infoUsuario){
      return this.infoUsuario.SucursalesFisicasUsuario.find(s => s.PorDefecto)?.PuntoDeVentaFacturacion.find(p => p.PorDefecto)?.VencimientoCertificadoFacturaElectronica;
    }
    return ''
  }

  getOtrasSucursales(){
    if(this.infoUsuario){
      return this.infoUsuario.SucursalesFisicasUsuario.filter(s => !s.PorDefecto);
    }
    return [];
  }

  cambiarSucursal(idSucursal: number){
    this.loading = true;
    this.subscribes.push(this.usuarioData.guardarSucursalFisicaPorDefecto(this.payLoadUser.id, idSucursal).subscribe({
      next: async _resp => {
        this.loading = false;
        if(_resp.isOk){
          this.alertaService.success('Sucursal cambiada correctamente', '', AlertaPosition.topCenter);
          await this.authData.setInfoUsuario();
          this.changeUsuario.recargarInfoUsuario();
          this.infoUsuario = this.authData.getInfoUsuario();
          this.calculoDiasFechaVencCert();
        }else{
          this.alertaService.error(_resp.message, '', AlertaPosition.topCenter);
        }
      }
    }))
  }

  calculoDiasFechaVencCert(){
    var fechaFinVencCert = new Date(this.infoUsuario.SucursalesFisicasUsuario.find(s => s.PorDefecto)?.PuntoDeVentaFacturacion.find(p => p.PorDefecto)?.VencimientoCertificadoFacturaElectronica).getTime();
    var fechaHoy = new Date().getTime();
    var diff = fechaFinVencCert - fechaHoy;
    this.diasVencCert = Math.round(diff/(1000*60*60*24));

    // console.log(this.diasVencCert);
  }

  getTooltip(page) {
    if (page.label == 'ACERCA DE') {
      return "GESTIÓN COMERCIAL - VERSION: " + environment.appVersion;
    } else
      return page.label;
  }

  estilosYalertasToolbar() {
    // Voy a ver en que env estoy dependiendo asigno la clase
    switch (this.env.name) {

      case 'local':
        this.envClass = 'sa-toolbar-local'
        break;

      case 'development':
        this.envClass = 'sa-toolbar-development'
        break;

      case 'testing':
        this.envClass = 'sa-toolbar-testing'
        break;

      case 'stage':
        this.envClass = 'sa-toolbar-staging'
        break;

      case 'production':
        this.envClass = 'sa-toolbar-production'
        break;

      default:
        this.envClass = 'sa-toolbar-local'
    }
  }

}
