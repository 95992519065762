import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangeUsuarioService {

  recargarInfoUsuarioEmit: EventEmitter<any> = new EventEmitter();

  constructor() { }

  recargarInfoUsuario() {
    this.recargarInfoUsuarioEmit.emit();
  }
}
