import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { LoadingModule } from './ui/loading/loading.module';
import { ModalConfirmModule } from './ui/modal-confirm/modal-confirm.module';
import { ModalModule } from './ui/modal/modal.module';
import { TitleModule } from './ui/title/title.module';
// import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule,
    TitleModule,
    ModalModule,
    ModalConfirmModule,
    LoadingModule,
    // HighchartsChartModule
  ],
  exports: [
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    ModalModule,
    TitleModule,
    ModalModule,
    ModalConfirmModule,
    LoadingModule,
    // HighchartsChartModule
  ]
})
export class SharedModule { }
